.aside {
  width: 400px;
  height: 100%;
  padding: 20px;
  background-color: var(--color-main);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 100%;
  height: 30px;
  margin: 40px 0;
}

.isFetching {
  margin-top: 20px;
  color: var(--color-base);
}

.notes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}

.note {
  width: 100%;
  background-color: var(--color-cold);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
}

.note:hover {
  background-color: var(--color-base);
}

.note__selected {
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--color-details);
}
.note__title {
  font-size: 14px;
  padding: 15px 10px;
  font-weight: thin;
  max-width: 70%;
  color: var(--color-main);
}

.note__icon {
  width: 20px;
  height: 20px;
}
